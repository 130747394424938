.PageNotFound {
  display: grid;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-items: center;
  gap: 2rem;
  height: 60vh;
  svg {
    width: 400px;
    height: 100%;
  }
  h1 {
    color: var(--title-color);
    margin: 0;
  }
  p {
    color: var(--subtitle-color);
    margin: 0;
  }
  @media (max-width: 960px) {
    height: 60vh;
    svg {
      width: 200px;
      height: 100%;
    }
  }
}
